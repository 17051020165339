'use client';

import { Notification, toaster } from '@appkit4/react-components/notification';
import React from 'react';

type Props = {
  status: 'warning' | 'success' | 'error' | '';
  title?: string;
  message: string;
};

const useToast = () => {
  const ele = (toastConfig: Props) => <Notification {...toastConfig} />;

  return (config: Props) =>
    toaster.notify(ele(config), {
      duration: 3000,
    });
};

export default useToast;
