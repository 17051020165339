import { z } from 'zod';

// Define a schema for a single email using Zod's built-in email validation
const emailSchema = z.string().email();

// Define a schema for the comma-separated email list
export const commaSeparatedEmailsSchema = z.string().refine(
  (value) => {
    // Split the string by commas to get individual emails
    const emails = value.split(',').map((email) => email.trim());

    // Validate each email using the email schema and check if it belongs to '@pwc.com'
    for (const email of emails) {
      const result = emailSchema.safeParse(email);
      if (!result.success || !email.endsWith('@pwc.com')) {
        return false;
      }
    }

    return true;
  },
  {
    message: 'Invalid email format or domain in the list',
  },
);
