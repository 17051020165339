import { useSession } from 'next-auth/react';

import { queryClient } from '@/services/QueryClient';

export const useGetPermissions = () => {
  const session = useSession();
  const isLoggedIn = session.status === 'authenticated';
  return queryClient.Users.userPermssions.useQuery(
    ['user permissions'],
    {},
    { enabled: isLoggedIn },
  );
};
