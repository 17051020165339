'use client';

import './Header.styles.css';

import { Avatar } from '@appkit4/react-components/avatar';
import { DropdownButton } from '@appkit4/react-components/dropdown';
import { Header as AppKitHeader } from '@appkit4/react-components/header';
import { usePathname, useRouter } from 'next/navigation';
import { signOut, useSession } from 'next-auth/react';
import { useTranslations } from 'next-intl';
import React, { useCallback, useMemo, useState } from 'react';

import { useGetPermissions } from '@/hooks/useGetPermissions';
import { useInviteFreeTrial } from '@/hooks/useInviteFreeTrial';
import useToast from '@/hooks/useToast';
import { useActiveChatStore } from '@/store/useActiveChatStore';
import { formatUserName } from '@/utils/formatUserName';

import ChatHeaderTabs from '../ChatHeaderTabs/ChatHeaderTabs';
import { MainHeaderTabs } from '../MainHeaderTabs/MainHeaderTabs';
import { ShareModal } from '../ShareModal/ShareModal';

const PrefixGenerator = (iconName: string) => {
  return <span className={`Appkit4-icon icon-${iconName}`} />;
};

export const UserAvatar = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const toast = useToast();
  const t = useTranslations();
  const session = useSession() as unknown as {
    data: { user: { name: string } };
    status: string;
  };
  const {
    data: permissionsData,
    isLoading: fetchingPermissions,
    isError: permissionsError,
  } = useGetPermissions();

  const { mutate: inviteUsers, isLoading: invitingUsers } =
    useInviteFreeTrial();

  const inviteDisabled = useMemo(
    () =>
      fetchingPermissions ||
      permissionsData?.body.isFreeTrialUser ||
      permissionsError,
    [permissionsError, permissionsData, fetchingPermissions],
  );

  if (session.status === 'authenticated') {
    const formattedName = formatUserName(session.data.user.name);

    const handleInvite = (
      emailsToInvite: Array<string>,
      dates: Array<Date>,
      successHandler: () => void,
      failHandler: () => void,
    ) => {
      if (dates[0] && dates[1]) {
        inviteUsers(
          {
            body: {
              // casted to any due to either contract type issue or type bug
              // for some reason current type registers as array of arrays
              emailsToInvite: emailsToInvite as any,
              accessStartTime: dates[0].toISOString(),
              accessEndTime: dates[1].toISOString(),
            },
          },
          {
            onSuccess: () => {
              successHandler();
              toast({
                message: t('inviteSuccess'),
                status: 'success',
              });
            },
            onError: () => {
              failHandler();

              toast({
                message: t('inviteFail'),
                status: 'error',
              });
            },
          },
        );
      }
    };

    return (
      <>
        <DropdownButton
          onSelect={async (_, item) => {
            item.onClick();
          }}
          customTriggerNode
          customTriggerClassName="custom-node"
          data={[
            {
              value: 'Free Trial Invite',
              label: 'Free Trial Invite',
              iconName: 'share-outline',
              onClick: () => {
                setModalVisible(true);
              },
              disabled: inviteDisabled,
            },
            {
              value: 'Logout',
              label: 'Logout',
              iconName: 'logout-outline',
              onClick: () => {
                signOut({ callbackUrl: '/' });
              },
            },
          ]}
          prefixTemplate={(item) => PrefixGenerator(item.iconName)}
        >
          <button type="button">
            <Avatar label={formattedName} disabled={false} />
          </button>
        </DropdownButton>
        <ShareModal
          visible={modalVisible}
          setVisible={setModalVisible}
          isLoading={invitingUsers}
          modalAction={(formValues, onSuccess, onFail) => {
            const emails = formValues.emails
              .trim()
              .replaceAll('\n', '')
              .split(',');
            handleInvite(emails, formValues.dates, onSuccess, onFail);
          }}
        />
      </>
    );
  }
  return null;
};

export const Header = () => {
  const t = useTranslations();
  const { workflowName } = useActiveChatStore();
  const path = usePathname();
  const router = useRouter();
  const ContentTemplate = useCallback(() => {
    if (path.includes('/publishedWorkflow')) {
      return <ChatHeaderTabs />;
    }
    return <MainHeaderTabs />;
  }, [path]);

  const headerTitleTemplate = () => workflowName ?? t('aiStudio');

  return (
    <AppKitHeader
      type="transparent"
      onClickLogo={() => router.push('/')}
      titleTemplate={headerTitleTemplate}
      contentTemplate={ContentTemplate}
      userTemplate={UserAvatar}
    />
  );
};
