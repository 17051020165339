export const formatUserName = (userName: string = '') => {
  const parts = userName.split(' ');
  if (parts.length > 1) {
    // If there are spaces and at least two elements in the array, return the first character of the first two words
    if (parts[0] && parts[1]) {
      return (parts[0].charAt(0) + parts[1].charAt(0)).toUpperCase();
    }
  }
  // If there are no spaces or not enough elements in the array, return the first two characters of the string
  return userName.slice(0, 2).toUpperCase();
};
