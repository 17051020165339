import { create } from 'zustand';

interface IStore {
  workflowName?: string;
  setWorkflowName: (name?: string) => void;
}

export const useActiveChatStore = create<IStore>()((set) => ({
  setWorkflowName: (newName) => set(() => ({ workflowName: newName })),
}));
